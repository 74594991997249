


































import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_COLOR = 'var(--primarycolor-pure)';

@Component
export default class Print extends Vue {
  @Prop({ default: DEFAULT_COLOR }) fill!: string;
}
