
















































































import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

import store from '@/store';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import AvatarRanking from '@/components/AvatarRanking/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Loading from '@/components/Loading/Loading.vue';
import Tabs, { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';

import RankingLoading from '@/components/Ranking/RankingLoading.vue';
import RankingUser from '../RankingUser/RankingUser.vue';

import GamificationService from '@/services/Gamification/gamificationService';

import { RankingData, RankingMonth, User } from '@/globalInterfaces/Gamification';
import { TAB_NAME_GENERAL, TABS } from '@/components/Ranking/constants';

const LIMIT_DEFAULT = 10;
const PAGE_DEFAULT = -1;

const MAX_USERS_RANKING = 100;

const ONE_HUNDRED_USERS = 100;
const TEN_USERS = 10;

@Component({
  components: {
    BoxContainer,
    AvatarRanking,
    RankingLoading,
    RankingUser,
    FeedbackUser,
    Loading,
    Tabs,
  },
  filters: {
    formatName(user: User) {
      if (!user) return '';

      const { idstudent } = store.getters.profile;
      const userName = user.nickname || user.name;

      const splitName = userName.split(' ');

      if (idstudent && user.ID === idstudent) return `${splitName[0]} (você)`;

      return splitName[0];
    },
  },
})
export default class RankingUsers extends Vue {
  @Prop({ default: null }) userRankingGeneral!: RankingData | null;
  @Prop({ default: false }) loading!: boolean;

  private isLoadingRanking = true;
  private isLoadingMore = false;
  private errorRanking = false;

  private totalPage = PAGE_DEFAULT;
  private page = PAGE_DEFAULT;
  private limit = LIMIT_DEFAULT;

  private ranking: RankingData[] = [];
  private tabs: TabsMenuInterface[] = TABS;

  private userRankingLocal: RankingData | null = null;

  private GamificationService = new GamificationService();

  created() {
    this.getMonthlyRanking();
  }

  get profile() {
    return this.$store.getters.profile;
  }

  get idStudent() {
    return this.profile?.idstudent;
  }

  get isLimitPage() {
    return (this.page * this.limit) >= this.totalPage;
  }

  get isMoreOneHundredUsers() {
    return this.ranking.length >= MAX_USERS_RANKING;
  }

  get activeTabIsGeneral() {
    return this.tabs.find((tab) => tab.active)?.title === TAB_NAME_GENERAL;
  }

  get currentMonth() {
    return new Date().getMonth() + 1;
  }

  get quantityUsers() {
    return this.activeTabIsGeneral ? ONE_HUNDRED_USERS : TEN_USERS;
  }

  @Watch('tabs')
  getRankingByActiveTab() {
    this.ranking = [];

    if (this.activeTabIsGeneral) this.getGeneralRanking();
    else this.getMonthlyRanking();
  }

  async getGeneralRanking() {
    try {
      if (this.isLimitPage || this.isMoreOneHundredUsers) return;

      this.errorRanking = false;

      if (this.ranking.length) this.isLoadingMore = true;
      else this.isLoadingRanking = true;

      const { ranking, total } = await this.GamificationService.getRanking({
        page: this.page + 1,
        limit: this.limit,
      });

      if (ranking && ranking.length) {
        this.ranking = [...this.ranking, ...ranking];
        this.userRankingLocal = this.userRankingGeneral;

        this.totalPage = Math.floor(total / this.limit);
        this.page += 1;
      }
    } catch (error) {
      this.errorRanking = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o ranking geral',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingRanking = false;
      this.isLoadingMore = false;
    }
  }

  async getMonthlyRanking() {
    try {
      const newRanking = [];

      this.errorRanking = false;
      this.isLoadingRanking = true;

      const data = {
        quantity: TEN_USERS,
        month: this.currentMonth,
      };

      const ranking = await this.GamificationService.getUserRankingMonth(data);

      if (ranking?.length) {
        newRanking.push(...this.setDataMonthlyRanking(ranking));

        this.ranking = newRanking;

        await this.getMeRankingMonth();
      }
    } catch (error) {
      this.errorRanking = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o ranking mensal',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingRanking = false;
    }
  }

  async getMeRankingMonth() {
    try {
      const userRanking = await this.GamificationService.getMeRankingMonth({
        month: this.currentMonth,
      });

      if (userRanking.length) {
        const newUserRanking = this.setDataMonthlyRanking(userRanking);

        [this.userRankingLocal] = newUserRanking;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o seu ranking mensal',
        status: 'error',
      });

      console.error(error);
    }
  }

  handleRedirectPlan() {
    this.$router.push({
      name: 'Store',
    });
  }

  setDataMonthlyRanking(ranking: RankingMonth[]) {
    return ranking.reduce((acc: RankingData[], currentRanking: RankingMonth) => {
      acc.push({
        ID: currentRanking.ID,
        points: currentRanking.points,
        ranking: currentRanking.position,
        month_index: currentRanking.position,
        year_index: currentRanking.position,
        month_points: currentRanking.points,
        created_date: '',
        updated_date: '',
        level: currentRanking.level,
        user: currentRanking.user,
      });

      return acc;
    }, []);
  }

  changeTabActive(index: number, updatedTabs: Array<TabsMenuInterface>) {
    this.tabs = updatedTabs;
  }
}
