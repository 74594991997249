

















































import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--feedbackcolor-error-medium)';

@Component
export default class DislikedDoubt extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
}
